<template>
  <audoora-header v-if="blok.header?.content" :blok="blok.header.content" class="sticky top-0 z-30" />
  <main
    v-editable="blok"
    class="relative isolate z-10 flex min-h-dvh flex-grow flex-col"
    :class="{
      '-mt-28': behindHeader,
      'pt-28': blok.body?.at(0)?.component === 'hero-video',
      '[&>:first-child]:!pt-28': blok.body?.at(0)?.body?.at(0).component === 'hero-video',
    }"
  >
    <multipage-dialog-root>
      <storyblok-component v-for="childBlok in blok.body" :key="childBlok._uid" :blok="childBlok" class="z-10" />
      <page-flyout v-if="blok.phoneNumber" v-editable="blok" label="Rufen Sie uns an" icon="phone">
        <a class="text-2xl font-semibold" :href="`tel:${blok.phoneNumber}`">{{ blok.phoneNumber }}</a>
        <div class="inline-images prose prose-base ml-auto mt-2 leading-tight dark:prose-invert" v-html="phoneText" />
      </page-flyout>
    </multipage-dialog-root>
  </main>
  <audoora-footer v-if="blok.footer?.content" :blok="blok.footer.content" />
  <footer v-if="blok.banners?.length" class="sticky bottom-0 z-20 flex flex-col">
    <storyblok-component v-for="childBlok in blok.banners" :key="childBlok._uid" :blok="childBlok" />
  </footer>
</template>

<script setup lang="ts">
import type { PageStoryblok } from "~/storyblok-component-types"
import { isEditorKey } from "~/injection-symbols"
import type { withHeaderFooter } from "~/utils/types"

const props = defineProps<{ blok: withHeaderFooter<PageStoryblok>; }>()
const isEditor = inject(isEditorKey)

usePageSeo(props.blok)

const phoneText = renderRTF(props.blok?.phoneText)

onMounted(() => {
  if (!isEditor && location.hash) {
    setTimeout(() => {
      window.scrollTo(
        0,
        location.hash ? (document.querySelector(useRoute().hash) as HTMLElement).getBoundingClientRect().top + window.scrollY - 120 : 0,
      )
    }, 100)
  }
})

const behindHeader = computed(() => {
  function shouldReversePad(element: any) {
    switch (element.component) {
      case "spacer":
        return true
      case "asset-image":
        return true
      case "hero":
        return true
      case "hero-video":
        return true
      case "grid":
        return shouldReversePad(element.body?.at(0))
      default:
        return false
    }
  }

  return props.blok.body?.length ? shouldReversePad(props.blok.body?.at(0)) : false
})
</script>
